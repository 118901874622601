import React, {useRef, useState} from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	ChakraProvider,
	Text,
} from '@chakra-ui/react';
import {isBoolean} from 'lodash';
import ReactDOM from 'react-dom';

export type ConfirmProps = {
	title: string;
	size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'xs' | '3xl' | '4xl' | '5xl' | '6xl' | 'full';
	content?: React.ReactNode | React.ReactNode[];
	validate?: () => true | string;
	onOk: () => void;
	onDestroy?: () => void;
};

const Modal: React.FC<ConfirmProps> = ({
	title,
	size = 'sm',
	content = '',
	validate = () => true,
	onOk,
	onDestroy,
}) => {
	const [error, setError] = useState('');
	const [isOpen, setIsOpen] = useState(true);
	const cancelRef = useRef(null);

	const closeModal = () => {
		setIsOpen(false);
		if (onDestroy) setTimeout(onDestroy, 500);
	};

	const confirmModal = () => {
		if (isBoolean(validate())) {
			setError('');
			onOk();
			setTimeout(closeModal, 500);
		} else {
			setError(String(validate()));
		}
	};

	return (
		<>
			<AlertDialog
				size={size}
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={closeModal}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							{title}
						</AlertDialogHeader>

						<AlertDialogBody>
							{content}
							{error && (
								<Text color='red.500' fontSize='sm' mt={2}>
									{error}
								</Text>
							)}
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={closeModal}>
								Cancel
							</Button>
							<Button colorScheme='red' onClick={confirmModal} ml={3}>
								OK
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};

export const confirm = (props: ConfirmProps) => {
	const domNode = document.getElementById('confirm-modal-div') || document.createElement('div');

	if (domNode.id !== 'confirm-modal-div') {
		domNode.id = 'confirm-modal-div';
		document.body.appendChild(domNode);
	}

	const onDestroy = () => {
		ReactDOM.unmountComponentAtNode(domNode);
	};

	// eslint-disable-next-line react/no-render-return-value
	return ReactDOM.render(
		<ChakraProvider>
			<Modal {...props} onDestroy={onDestroy} />
		</ChakraProvider>,
		domNode,
	);
};
